body {
  &.page-print {
    padding-top: 0;

    #usp-bar {
      display: none;
    }

    .page-main {
      padding: 15px;
    }
  }
}
