.block-search,
#algoliaAutocomplete {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100vw - 540px);
  max-width: 1920px;
  margin-top: 0;
  padding-left: 0;
  transform: translateX(-50%);

  @include mobile {
    position: relative;
    top: auto;
    left: auto;
    flex-grow: 1;
    order: 2;
    width: calc(100% - 110px);
    margin-bottom: 0;
    transform: none;
    transition: 300ms ease-in-out max-width;
  }

  .block-content {
    #search_mini_form {
      .search,
      #algolia-searchbox {
        .control,
        .algolia-autocomplete {
          position: relative;
          padding: 0;

          #search {
            left: auto;
            height: 40px;
            margin: 0;
            padding-right: 30px;
            transition: 300ms ease-in-out background-color;
            border: none;
            border-radius: 20px;
            background-color: $lightgrey;
            font-family: "Fira Sans";
            text-overflow: ellipsis;
            white-space: nowrap;

            &:not([disabled]):focus {
              background: white;
            }
          }

          .nested {
            display: none;
          }
        }

        .label {
          @include mobile {
            display: none;
          }
        }

        .magnifying-glass {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .actions {
        button.action.search {
          height: 40px;

          &::before {
            content: "\e986";
            color: $black;
            font-family: icomoon;
          }

          &[disabled] {
            opacity: 1;
          }
        }
      }

      &.active {
        .search {
          .control {
            #search {
              background-color: white;
            }
          }
        }
      }
    }
  }

  .aa-Autocomplete {
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: -10px;
      left: 50%;
      width: calc(100% + 30px);
      min-width: calc(100vw - 510px);
      height: 90px;
      transform: translateX(-50%);
      transition: 500ms opacity;
      border-radius: 30px 30px 0 0;
      opacity: 0;
      background: $lightgrey;
      pointer-events: none;
    }

    &[aria-expanded="true"] {
      position: fixed;
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);

      @include mobile {
        top: 10px;
      }

      &::after {
        @include tabletlandscape {
          content: "";
          display: block;
          position: absolute;
          z-index: -2;
          top: -10px;
          left: 50%;
          width: 100vw;
          height: 90px;
          transform: translateX(-50%);
          background: rgb(0 0 0 / 70%);
        }

        @include tabletportrait {
          content: "";
          display: block;
          position: absolute;
          z-index: -2;
          top: -10px;
          left: 50%;
          width: 100vw;
          height: 90px;
          transform: translateX(-50%);
          background: rgb(0 0 0 / 70%);
        }

        @include mobile {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          top: -60px;
          left: 50%;
          width: 100vw;
          height: 140px;
          transform: translateX(-50%);
          background: rgb(0 0 0 / 70%);
        }
      }

      &::before {
        transition: 0;
        opacity: 1;

        @include tabletlandscape {
          min-width: calc(100vw - 60px);
        }

        @include tabletportrait {
          min-width: calc(100vw - 60px);
        }

        @include mobile {
          z-index: 2;
          min-width: 100vw;
          border-radius: 0;
        }
      }

      .aa-Form {
        position: relative;
        left: 50%;
        min-width: calc(100vw - 540px);
        max-width: 100%;
        transform: translateX(-50%);

        @include tabletlandscape {
          min-width: calc(100vw - 90px);
        }

        @include tabletportrait {
          min-width: calc(100vw - 90px);
        }

        @include mobile {
          z-index: 2;
          min-width: calc(100vw - 40px);
        }

        .aa-InputWrapper {
          .aa-Input {
            outline: 1px solid $brightblue;
            background: white;
          }
        }
      }
    }
  }

  .aa-Form {
    min-width: unset;
    transition: 300ms ease-in-out min-width;

    @include tabletlandscape {
      max-width: 740px;
    }

    @include tabletportrait {
      max-width: 740px;
    }

    .aa-InputWrapperPrefix {
      .aa-Label {
        .aa-SubmitButton {
          top: 5px;
          right: 5px;
          width: 30px;
          height: 30px;
          padding: 0;
          transition: 300ms ease-in-out transform;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: $black;
          }

          &:hover {
            transform: scale(1.1);
          }

          .aa-SubmitIcon {
            position: relative;
            color: white;
          }
        }
      }
    }

    .aa-LoadingIndicator {
      display: none;
    }

    .aa-InputWrapper {
      .aa-Input {
        left: auto;
        height: 40px;
        margin: 0;
        padding-right: 37px;
        padding-left: 15px;
        transition: 300ms ease-in-out background-color;
        border: none;
        border-radius: 20px;
        background-color: $lightgrey;
        font-family: "Fira Sans";
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not([disabled]):focus {
          outline: 1px solid $brightblue;
          background: white;
        }
      }

      .nested {
        display: none;
      }
    }

    .aa-InputWrapperSuffix {
      .aa-ClearButton {
        display: flex;
        top: 5px;
        right: 50px;
        width: 30px;
        height: 30px;
        padding: 0;
        transition: 300ms ease-in-out top;
        background: transparent;

        svg {
          fill: $black;
        }

        @include mobile {
          // position: fixed;
          // top: 180px;
          // right: 15px;
          // width: 40px;
          // height: 40px;
          // background: $lightgrey;
        }

        .aa-ClearIcon {
          @include mobile {
            width: 50px;
            height: 50px;
            color: $black;
          }
        }
      }
    }

    .label {
      @include mobile {
        display: none;
      }
    }

    .magnifying-glass {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .actions {
    button.action.search {
      height: 40px;

      &::before {
        content: "\e986";
        color: $black;
        font-family: icomoon;
      }

      &[disabled] {
        opacity: 1;
      }
    }
  }

  &.active {
    .search {
      .control {
        #search {
          background-color: white;
        }
      }
    }
  }
}
