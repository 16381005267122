body {
  &.wesupply-orders-view {
    .page-main {
      .columns {
        padding: 30px 0;
      }
    }

    .loading-container {
      .loading-image {
        height: 40px;
      }
    }
  }
}
