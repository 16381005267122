#store-notification {
  display: block;
  position: relative;
  max-height: 100px;
  padding: 0;
  overflow: hidden;
  transition: 300ms ease-in-out max-height;
  background: $beige;

  &.hidden {
    display: block;
    max-height: 0;
  }

  .inner-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 10px 40px 10px 15px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .close {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;

    &::before {
      content: "\e902";
      color: $black;
      font-family: icomoon;
    }

    span {
      display: none;
    }
  }
}
