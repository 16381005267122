$black: #121011;
$lightgreen: #e2ede7;
$red: #ff2600;
$green: #79d0a3;
$darkred: #a62c00;
$lightgrey: #f5f5f5;
$lightblue: #d4e3f4;
$beige: #fff2c3;
$grey: #d4d4d4;
$yellow: #ffe500;
$darkgrey: #939393;
$brightblue: #1e6bc6;
$darkblue: #002e54;
$redgradient: linear-gradient(270deg, rgb(255 38 0) 0%, rgb(166 44 0 / 100%) 100%);
$color-array: (
  black: $black,
  lightgreen: $lightgreen,
  red: $red,
  green: $green,
  darkred: $darkred,
  lightgrey: $lightgrey,
  lightblue: $lightblue,
  beige: $beige,
  grey: $grey,
  yellow: $yellow,
  darkgrey: $darkgrey,
  brightblue: $brightblue,
  darkblue: $darkblue,
  redgradient: $redgradient,
);

@each $color-name, $color-value in $color-array {
  .background-#{"" + $color-name} {
    background: $color-value;
  }
  .text-#{"" + $color-name} {
    color: $color-value;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
