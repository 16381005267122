.block-click-frenzy {
  position: relative;
  padding: 26px 15px;
  background-color: $lightblue;
  background-position: right center !important;
  background-size: auto 100% !important;
  color: $black;

  @include mobile {
    background-position: 200px center !important;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: "Oswald";
    font-weight: 400;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;

    @include mobile {
      max-width: 200px;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 1000px;
    height: 100%;
    background-color: $lightblue;

    @include mobile {
      display: none;
    }
  }

  &::after {
    left: 100%;
  }

  &::before {
    right: 100%;
  }
}
