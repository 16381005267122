.block-callout {
  position: relative;
  margin: 50px 0;
  padding: 30px 30px 28px;
  background-color: transparent;
  color: $black;

  @include mobile {
    padding: 30px 15px 28px;
  }

  .pagebuilder-column-group {
    align-items: center;

    .pagebuilder-column-line {
      gap: 15px;

      @include tabletportrait {
        flex-wrap: wrap;
      }

      @include media(tabletportrait, mobile) {
        min-width: calc(100% + 15px);
      }
    }
  }

  .pagebuilder-column {
    min-width: 210px;

    @include mobile {
      flex-basis: calc(50% - 15px);
      min-width: calc(50% - 15px);
    }

    @include tabletportrait {
      flex-basis: calc(25% - 15px);
      min-width: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.callout-australia {
      .col {
        .icon {
          img {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background-image: url("../images/static-blocks/australia.svg");
          }
        }
      }
    }

    &.callout-pickup {
      .col {
        .icon {
          img {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background-image: url("../images/static-blocks/pickup.svg");
          }
        }
      }
    }

    &.callout-delivery {
      .col {
        .icon {
          img {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background-image: url("../images/static-blocks/delivery.svg");
          }
        }
      }
    }

    &.callout-warranty {
      .col {
        .icon {
          img {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background-image: url("../images/static-blocks/warranty.svg");
          }
        }
      }
    }
  }

  .col {
    display: flex;
    align-items: center;

    @include media(tabletportrait, mobile) {
      flex-direction: column;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-right: 10px;
      border-radius: 50px;
      background: $lightgrey;

      @include tabletlandscape {
        width: 80px;
        height: 80px;
      }

      @include mobile {
        width: 60px;
        height: 60px;
      }

      @include media(tabletportrait, mobile) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      &::before {
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include tabletlandscape {
          width: 40px;
          height: 40px;
        }

        @include mobile {
          width: 30px;
          height: 30px;
        }
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;

        @include tabletlandscape {
          width: 40px;
          height: 40px;
        }

        @include mobile {
          width: 30px;
          height: 30px;
        }
      }
    }

    .text {
      @include media(tabletportrait, mobile) {
        text-align: center;
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }

    h4 {
      margin: 0;
      color: $black;
      font-size: 18px;
      font-weight: 600;

      @include media(tabletlandscape, mobile) {
        font-size: 16px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      color: $black;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

body.catalog-product-view {
  .block-callout {
    .pagebuilder-column {
      @include mobile {
        padding: 15px 0;
        border-radius: 15px;
        background: #fff;

        .col {
          .icon {
            background: #f5f5f5;
          }
        }
      }
    }

    .col {
      .icon {
        background: white;
      }
    }
  }
}
