body {
  .page-header {
    .header {
      .minicart-wrapper {
        // &::before {
        //   content: "";
        //   display: block;
        //   position: fixed;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   transition: 300ms ease-in-out opacity;
        //   opacity: 0;
        //   background: rgb(0 0 0 / 50%);
        //   pointer-events: none;
        // }

        .showcart {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 0 10px;
          transition: 300ms ease-in-out all;
          border: 1px solid $darkgrey;
          border-radius: 5px;

          &:hover {
            border-color: white;
          }

          &::before {
            display: none;
          }

          .value {
            max-width: 100px;
            overflow: hidden;
            transform: ease-in-out 300ms max-width;

            &.empty {
              max-width: 0;
            }

            .price {
              margin-right: 10px;
              color: white;
              font-size: 14px;
              font-weight: 600;

              @include mobile {
                display: none;
              }
            }
          }

          .icon {
            &::before {
              content: "\e901";
              color: white;
              font-family: icomoon;
              font-size: 17px;
            }
          }

          &.active {
            &:hover {
              &::before {
                color: white;
              }
            }
          }

          .counter.qty {
            display: flex;
            position: absolute;
            top: 7px;
            right: 4px;
            align-items: center;
            justify-content: center;
            min-width: 5px;
            height: 5px;
            margin: 0;
            padding: 0;
            overflow: visible;
            border-radius: 10px;
            background-color: transparent;
            color: white;
            font-size: 0;
            font-weight: 600;

            .counter-number:not(:empty) {
              display: flex;
              align-self: center;
              justify-content: center;
              width: 5px;
              height: 5px;
              border-radius: 10px;
              background-color: $red;
              line-height: 20px;
            }

            .loading-mask {
              left: -1px;
              width: 7px;
              height: 7px;
              animation: minicart ease-in-out infinite alternate;
              animation-duration: 0.5s;
              border-radius: 5px;
              opacity: 1;
              background-color: $red;

              img {
                display: none;
              }
            }
          }
        }

        .ui-dialog {
          display: block !important;

          .block-minicart {
            display: block;
          }
        }

        &.active {
          &::before {
            opacity: 1;
          }

          .mage-dropdown-dialog {
            &::before {
              // @include mobile {
              content: "";
              position: fixed;
              z-index: -1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgb(0 0 0 / 50%);
              pointer-events: none;

              // }
            }
          }

          .block-minicart {
            z-index: 999;
            transform: translateX(0) !important;
          }
        }
      }

      .block-minicart {
        display: none;
        position: fixed;
        top: 120px;
        right: 0;
        width: 95vw;
        min-width: 0;
        max-width: 350px;
        height: calc(100vh - 120px);
        margin-top: 0;
        padding: 30px 15px;
        overflow-y: hidden;
        transform: translateX(100%);
        transition: 300ms ease-in-out transform;
        border: none;
        background: white;
        box-shadow: 0 20px 20px rgb(0 0 0 / 15%);

        @include mobile {
          top: 140px;
          height: calc(100vh - 140px);
        }

        #minicart-content-wrapper {
          .block-content {
            .actions {
              margin-top: 0;

              .primary {
                margin: 0 0 15px;

                #top-cart-btn-checkout {
                  position: relative;
                  height: 50px;
                  overflow: hidden;
                  transition: 300ms ease-in-out text-indent;
                  border: none;
                  background-color: $brightblue;

                  &::after {
                    content: "\e901";
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 100%;
                    transform: translateX(50px);
                    transition: 300ms ease-in-out transform;
                    background: $black;
                    font-family: icomoon;
                    font-size: 24px;
                    text-indent: 0;
                  }

                  &:hover {
                    text-indent: -50px;

                    &::after {
                      transform: none;
                    }
                  }
                }
              }

              .secondary {
                margin-top: 10px;
              }
            }

            #afterpay-cta-mini-cart {
              display: none;
            }

            .minicart-items-wrapper {
              width: 100%;
              height: 100% !important;
              max-height: calc(100vh - 310px);
              margin: 0;
              padding: 0;
              border: 0;

              #mini-cart {
                .item {
                  &.product {
                    margin-bottom: 10px;
                    padding: 10px 0;
                    border-top: 0;
                    border-bottom: 1px solid $grey;
                    background: white;
                  }

                  > .product {
                    .product-item-photo {
                      width: 80px;

                      .product-image-container {
                        width: 80px !important;

                        .product-image-wrapper {
                          &::before {
                            content: "";
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: auto;
                            border-radius: 5px;
                            background: $lightgrey;
                            aspect-ratio: 1 / 1;
                            mix-blend-mode: darken;
                          }

                          img.product-image-photo {
                            font-size: 0;
                          }
                        }
                      }
                    }

                    .product-item-details {
                      position: relative;
                      padding-left: 90px;

                      .product-item-name {
                        margin-bottom: 5px;

                        a {
                          display: -webkit-box;
                          overflow: hidden;
                          color: $black;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 19px;
                          text-align: left;
                          text-overflow: ellipsis;
                          white-space: normal;
                          hyphens: auto;
                          overflow-wrap: anywhere;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                        }
                      }

                      .product.options {
                        .content {
                          .product.options.list {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            width: fit-content;
                            margin-bottom: 0;

                            dt,
                            dd {
                              margin-bottom: 0;
                            }
                          }
                        }
                      }

                      .product-item-pricing {
                        .price-container {
                          .price-wrapper {
                            .price-excluding-tax,
                            .price-including-tax {
                              display: flex;
                              flex-direction: row;
                              align-items: flex-end;
                              justify-content: flex-end;
                              margin-bottom: 5px;
                              gap: 5px;

                              .minicart-old-price {
                                .price {
                                  color: $darkgrey;
                                  font-size: 14px;
                                  font-weight: 600;
                                  text-decoration: line-through;
                                }
                              }

                              .minicart-price {
                                .price {
                                  font-size: 18px;
                                  font-weight: 600;
                                }
                              }
                            }
                          }
                        }

                        .qty {
                          margin-top: 0;

                          label {
                            font-size: 11px;
                          }

                          input {
                            height: 40px;
                          }

                          .update-cart-item {
                            display: inline-flex;
                          }
                        }
                      }

                      .actions {
                        .primary {
                          display: none;
                        }

                        .secondary {
                          a {
                            &::before {
                              content: "\e9ac";
                              color: $black;
                              font-family: icomoon;
                              font-size: 16px;
                            }

                            &:hover {
                              &::before {
                                color: $red;
                              }
                            }
                          }
                        }

                        &.product {
                          position: absolute;
                          right: 0;
                          bottom: 5px;
                        }
                      }
                    }
                  }
                }
              }
            }

            >.actions {
              .secondary {
                display: none;
              }
            }

            .items-total {
              margin: 0;
            }

            .subtotal {
              margin: 0 0 10px;

              .amount {
                .price-wrapper {
                  .price {
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .action {
            &.close {
              &::before {
                color: $black;
              }
            }
          }
        }

        .minicart-payment-methods {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;

          ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 10px 20px;
            gap: 5px;

            li {
              height: 25px;
              list-style: none;

              img {
                max-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}
