.category-carousel {
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: 30px auto;
  padding: 0 20px;

  @include mobile {
    padding: 0 15px;
  }

  .row-full-width-inner {
    border: 1px solid $grey;
    background: white;

    .pagebuilder-column-group {
      padding: 0;

      &.slick-slider {
        .slick-arrow {
          &.slick-next {
            right: 10px;
          }

          &.slick-prev {
            left: 10px;
          }

          &::before {
            color: $black;
          }
        }
      }

      .slick-list {
        width: 100%;

        .slick-track {
          display: flex;
          padding: 0;
          gap: 10px;

          .slick-slide {
            a {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 10px;

              img {
                max-height: 200px;
                transform: scale(1);
                transition: 300ms ease-in-out transform;
                will-change: transform;
              }

              h3 {
                color: $black;
                font-size: 18px;
              }

              &:hover {
                text-decoration: none;

                h3 {
                  text-decoration: none;
                }

                img {
                  transform: scale(1.05);
                }
              }
            }
          }
        }
      }
    }
  }
}
