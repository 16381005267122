@keyframes minicart {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.2);
  }
}

.page-header {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  height: auto;
  min-height: 0;
  transition: 300ms ease-in-out top;
  border: none;
  background-color: $black;

  .top-bar {
    display: flex;
    position: relative;

    .quick-menu {
      display: flex;
      position: absolute;
      right: 0;
      align-items: center;
      height: 30px;
      padding-right: 30px;

      @include mobile {
        display: none;
      }

      ul {
        display: flex;
        margin: 0;
        padding: 0;
        gap: 10px;

        li {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          color: white;
          list-style: none;

          &::before {
            width: 16px;
            height: 13px;
            margin-right: 5px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          a {
            color: white;
            font-size: 11px;
          }

          &.customer-service {
            &::before {
              content: "";
              display: inline-block;
              background-image: url("../images/static-blocks/customer-service.svg");
            }
          }

          &.track-order {
            &::before {
              content: "";
              display: inline-block;
              background-image: url("../images/static-blocks/track-order.svg");
            }
          }
        }
      }
    }
  }

  .panel.wrapper {
    position: relative;
    z-index: 1;
    top: 0;
    order: 98;
    width: 40px;
    border: none;

    @include mobile {
      display: none;
    }

    @include tabletportrait {
      display: none;
    }

    .panel.header {
      padding: 0;

      >.customer-menu {
        display: flex;
        flex-wrap: wrap;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
        padding: 0;
        transition: 300ms ease-in-out border-color;
        border: 1px solid $darkgrey;
        border-radius: 5px;

        &:hover {
          border-color: white;
        }

        >li {
          height: 0;
          margin: 0;
          padding: 0;
          font-size: 0;
          list-style: none;

          span,
          a {
            color: white;
          }

          &.authorization-link {
            a {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          &.new-account {
            &::before {
              content: "\e900";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              font-family: icomoon;
              font-size: 18px;
              pointer-events: none;
            }
          }

          &.customer-welcome {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 0;

            &::before {
              content: "\e900";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              font-family: icomoon;
              font-size: 16px;
              pointer-events: none;
            }

            >.customer-menu {
              display: none;
            }

            >.customer-name {
              &::after {
                content: "\e906";
                display: none;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                transform-origin: top;
                color: white;
                font-family: icomoon;
                font-size: 8px;
                pointer-events: none;
              }

              button {
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
                outline: 0;
                background: transparent;
                appearance: none;

                &:active {
                  border: none;
                  box-shadow: none;
                }
                span { display: none; }
              }

              &.active {
                &::after {
                  display: none;
                  transform: rotate(180deg) translateY(-50%);
                  color: $black;
                }
              }
            }

            &.active {
              &::before {
                color: white;
              }

              & ~ li {
                background: white;

                &.authorization-link {
                  a {
                    &::after {
                      transform: rotate(180deg) translateY(1px);
                    }
                  }
                }

                a,
                span {
                  color: $black;
                }
              }

              >.customer-menu {
                display: block;
                position: absolute;
                right: 0;
                min-width: fit-content;
                margin-top: 50px;
                border-radius: 5px;
                background: white;
                box-shadow: 0 20px 20px rgb(0 0 0 / 10%);
                white-space: nowrap;

                ul {
                  flex-direction: column;
                  margin: 0 10px;
                  padding: 0;

                  li {
                    display: flex;
                    width: 100%;
                    margin: 0;
                    border: 0;

                    a {
                      width: 100%;
                      padding: 5px;
                      user-select: none;

                      &::before {
                        content: "";
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        font-family: icomoon;
                      }

                      &:hover {
                        text-decoration: none;

                        &::before {
                          color: $red;
                        }
                      }
                    }

                    span,
                    a {
                      color: $black;
                      font-size: 14px;
                    }

                    &.wishlist,
                    &.compare,
                    &.welcome {
                      display: none;
                    }

                    &.authorization-link {
                      order: 99;
                      margin-bottom: 10px;

                      a {
                        &::before {
                          content: "\e907";
                        }
                      }
                    }

                    &.track-order {
                      a {
                        &::before {
                          content: "\e908";
                        }
                      }
                    }

                    &.spare-parts {
                      a {
                        &::before {
                          content: "\e994";
                        }
                      }
                    }

                    &.my-orders {
                      a {
                        &::before {
                          content: "\e905";
                        }
                      }
                    }

                    &.warranty {
                      a {
                        &::before {
                          content: "\e90c";
                        }
                      }
                    }

                    &.help-centre {
                      a {
                        &::before {
                          content: "\e909";
                        }
                      }
                    }

                    &.spare-parts {
                      a {
                        &::before {
                          content: "\e991";
                        }
                      }
                    }

                    &:not([class]) {
                      a {
                        &::before {
                          content: "\e90d";
                        }
                      }
                    }

                    &:first-child {
                      padding-top: 10px;
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }

                    &::before {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          &.greet.welcome {
            order: -1;

            span {
              display: block;
              max-width: 145px;
              padding-right: 5px;
              overflow: hidden;
              color: white;
              text-overflow: ellipsis;
              white-space: nowrap;

              &.not-logged-in {
                display: none;
              }
            }

            &.persistent {
              ~ li.new-account {
                display: none;
              }

              .not-logged-in {
                span {
                  &::before {
                    content: "\e900";
                    position: absolute;
                    top: 7px;
                    left: 15px;
                    color: white;
                    font-family: icomoon;
                    font-size: 18px;
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }

        .header.links {
          display: flex;
          flex-wrap: wrap;

          li {
            width: calc(50% - 5px);
            margin-left: 0;
            color: white;
            font-size: 11px;

            a {
              color: white;
            }

            &.wishlist,
            &.compare {
              display: none;
            }

            &.authorization-link {
              margin-left: 3px;

              &::before {
                content: "/ ";
              }

              a {

              }
            }

            &.greet {
              width: 100%;

              span {
                color: white;
              }
            }

            &:last-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .header {
    max-width: none;
    padding: 0 30px;

    @include mobile {
      padding: 0 15px;
    }

    &.content {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      align-items: center;
      height: auto;
      margin: 0;

      .ammenu-logo,
      .logo {
        display: inline-flex;
        position: relative;
        box-sizing: border-box;
        order: 0;
        width: 190px;
        max-height: calc(100% - 10px);
        margin-right: auto;
        margin-bottom: 0;
        margin-left: 0;
        padding-right: 10px;
        transition: ease-in-out 300ms transform;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          object-position: left center;

          @include mobile {
            max-width: 220px;
            margin: 10px auto;
          }
        }

        @include mobile {
          width: 100%;
          max-height: fit-content;
          padding: 0;
        }
      }

      .minicart-wrapper {
        position: relative;
        order: 99;
        margin: 0 0 0 10px;

        @include mobile {
          position: relative;
        }
      }
    }

    .exit-search {
      display: none;
      position: absolute;
      left: 15px;
      opacity: 0;
      pointer-events: none;

      @include mobile {
        display: flex;
        position: relative;
        left: auto;
        align-items: center;
        order: 1;
        width: 40px;
        height: 40px;
        margin-right: 15px;
      }

      &.search-open {
        pointer-events: all;

        #exit-search-icon span:nth-child(1) {
          top: 0;
          left: 5px;
          width: 50%;
          transform: rotate(-45deg);
          transform-origin: right bottom;
        }

        #exit-search-icon span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        #exit-search-icon span:nth-child(3) {
          top: 10px;
          left: 8px;
          width: 50%;
          transform: rotate(45deg);
          transform-origin: right top;
        }
      }

      #exit-search-icon {
        position: relative;
        width: 20px;
        height: 13px;
        margin: 0 auto;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      #exit-search-icon span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        opacity: 1;
        background: white;
      }

      #exit-search-icon span:nth-child(1) {
        top: 0;
        transform-origin: left center;
      }

      #exit-search-icon span:nth-child(2) {
        top: 5px;
        transform-origin: left center;
      }

      #exit-search-icon span:nth-child(3) {
        top: 10px;
        transform-origin: left center;
      }
    }
  }

  #toggle_button {
    display: none;
  }

  &.minified {
    position: fixed;
    max-height: 170px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 300ms ease-in-out all;
    animation: slideDown 0.35s ease-out;

    .top-bar {
      display: none;
    }

    .header.content {
      flex-wrap: nowrap;

      @include mobile {
        flex-wrap: wrap;
      }

      .ammenu-logo {
        width: 45px;
        min-width: 45px;
        height: 30px;
        overflow: hidden;

        img {
          width: auto;
          height: 30px;
          object-fit: cover;
        }
      }

      #sub-menu {
        flex-grow: 1;
        order: 2;
        width: 420px;

        @include mobile {
          flex-grow: initial;
          order: 99;
          width: calc(100% + 60px);
        }

        @include tabletportrait {
          width: 220px;
        }

        #open-categories {
          height: 40px;
        }
      }

      #algoliaAutocomplete {
        position: relative;
        left: auto;
        order: 3;
        width: auto;
        min-width: 300px;
        margin-right: 15px;
        transform: none;

        @include mobile {
          min-width: 100px;
        }

        @include tabletportrait {
          min-width: 260px;
        }

        .aa-Autocomplete {
          .aa-Form {
            min-width: 300px;

            @include mobile {
              min-width: 100px;
            }

            @include tabletportrait {
              min-width: 260px;
            }
          }

          &[aria-expanded="true"] {
            top: 20px;

            @include mobile {
              top: 10px;
            }

            .aa-Form {
              min-width: calc(100vw - 540px);

              @include tabletlandscape {
                min-width: calc(100vw - 90px);
              }

              @include tabletportrait {
                min-width: calc(100vw - 90px);
              }

              @include mobile {
                min-width: calc(100vw - 40px);
              }
            }
          }
        }
      }
    }
  }

  &.minified {
    .header .minicart-wrapper .block-minicart {
      @include mobile {
        top: 90px;
      }

      top: 70px;
      height: calc(100vh - 70px);

      #minicart-content-wrapper .block-content .minicart-items-wrapper {
        max-height: calc(100vh - 260px) !important;

        @include mobile {
          max-height: calc(100vh - 280px) !important;
        }
      }
    }

    .header {
      .ammenu-logo {
        @include mobile {
          display: none;
        }
      }
    }
  }

  #sub-menu {
    display: flex;
    position: relative;
    align-items: center;
    order: 99;
    width: 100%;
    height: 50px;
    margin-right: 10px;
    padding-right: 60px;
    padding-left: 0;
    gap: 5px;

    @include mobile {
      width: calc(100% + 60px);
      height: 20px;
      margin-top: 10px;
      margin-left: -30px;
      padding-right: 0;
      padding-left: 0;
    }

    #open-categories {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 10px;
      transition: 300ms ease-in-out border-color;
      border: 1px solid $darkgrey;
      border-radius: 5px;
      color: white;
      cursor: pointer;
      gap: 10px;

      @include mobile {
        position: absolute;
        top: -50px;
        left: 30px;
        width: 40px;
        height: 40px;
      }

      &:hover {
        border-color: white;
      }

      .text {
        text-wrap: nowrap;
        white-space: nowrap;

        @include mobile {
          display: none;
        }

        @include tabletportrait {
          display: none;
        }
      }
    }

    #sub-menu-inner {
      display: flex;
      align-items: center;
      overflow: auto visible;

      @include mobile {
        padding-left: 15px;
      }

      &::-webkit-scrollbar {
        display: none;

        @include tabletportrait {
          display: none;
        }

        @include mobile {
          display: none;
        }
      }
    }

    ul {
      display: flex;
      width: fit-content;
      margin: 0;
      padding: 0 5px;
      white-space: nowrap;
      gap: 10px;

      li {
        margin-bottom: 0;
        padding: 3px 0;
        list-style: none;

        a {
          padding: 3px 10px;
          color: white;
          font-size: 14px;
          font-weight: 400;

          &:hover {
            border-radius: 10px;
            background: white;
            color: $black;
            text-decoration: none;
          }
        }

        &.sale {
          a {
            color: $red;

            &:hover {
              color: $black;
            }
          }
        }
      }
    }

    #sub-scroll-left,
    #sub-scroll-right {
      display: flex;
      position: absolute;
      top: 50%;
      right: 30px;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      border-radius: 10px;
      background: transparent;
      cursor: pointer;

      @include mobile {
        display: none;
      }

      &:hover {
        background: rgb(255 255 255 / 20%);
      }

      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }

      &::after {
        content: "\e906";
        transform: rotate(90deg);
        transition: 300ms ease-in-out transform;
        color: white;
        font-family: icomoon;
        font-size: 7px;
      }
    }

    #sub-scroll-right {
      right: 0;
      border-right: 0;

      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}

body.search-open {
  overflow: hidden;
}

@include mobile {
  body.search-open {
    padding-top: 60px;

    .page-header {
      top: -40px;

      .block-search {
        max-width: calc(100% - 70px);
      }

      .header {
        .minicart-wrapper {
          .block-minicart {
            top: 60px;
          }

          .action.showcart {
            transform: translateX(60px);
          }
        }

        .ammenu-logo {
          transform: translate(-50%, -20px);
        }

        .hamburger {
          transform: translateX(-60px);
        }
      }
    }
  }

  body.sub-menu {
    .page-header {
      height: 170px;
    }

    .page-header .header .minicart-wrapper .block-minicart {
      top: 170px;
      height: calc(100vh - 170px);
    }

    .minicart-items-wrapper {
      max-height: calc(100vh - 360px) !important;
    }
  }

  body.sub-menu.minified {
    .page-header {
      @include mobile {
        height: 90px;
      }
    }

    .page-header .header .minicart-wrapper .block-minicart {
      top: 90px;
      height: calc(100vh - 90px);
    }

    .minicart-items-wrapper {
      max-height: calc(100vh - 300px) !important;
    }
  }
}

body.scrolled {
  .aa-Panel {
    top: 70px !important;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
