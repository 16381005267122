ol.products,
ol.product-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  gap: 15px;

  &.widget-product-carousel {
    grid-template-rows: 1fr 0 0 0 0 0;
    padding: 0 15px;

    &.slick-slider {
      padding: 0;
    }
  }

  @include fullhd {
    grid-template-columns: repeat(5, 1fr);
  }

  @include desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  @include laptop {
    grid-template-columns: repeat(4, 1fr);
  }

  @include tabletlandscape {
    grid-template-columns: repeat(3, 1fr);
  }

  @include tabletportrait {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    width: calc(100%);
    min-width: 0;
    max-width: 100%;
  }

  &.open {
    .product-item {
      display: flex !important;
    }
  }
}

ol.product-items {
  &.slick-slider {
    gap: initial;
    display: flex;
    flex-wrap: nowrap;

    .slick-arrow {
      display: none !important;
    }

    > .slick-list {
      width: 100%;
      padding-right: 7.5px;
      padding-left: 7.5px;

      .slick-dots {
        bottom: -30px;

        li {
          button {
            background: $brightblue;
          }

          &.slick-active {
            button {
              background: $brightblue;
            }
          }
        }
      }

      .slick-track {
        display: flex;

        .slick-slide {
          padding: 0 7.5px;

          > div {
            height: 100%;
            min-height: 100%;

            .product-item {
              display: flex !important;
              min-height: 100%;
              margin: 0 !important;

              .product-item-info {
                min-height: 100%;
              }
            }
          }

          @include mobile {
            .product-item {
              .product-item-info {
                flex-direction: column;

                .product-item-details {
                  margin-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-products .products-grid,
.product-items,
.product-items.slick-slider,
ol:not(#mini-cart):not(.minicart-items) {
  &.ais-Hits-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

    @include fullhd {
      grid-template-columns: repeat(5, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    @include laptop {
      grid-template-columns: repeat(4, 1fr);
    }

    @include tabletlandscape {
      grid-template-columns: repeat(3, 1fr);
    }

    @include tabletportrait {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
      width: calc(100%);
      min-width: 0;
      max-width: 100%;
    }

    li {
      > div {
        display: flex;
        width: 100%;
      }
    }
  }

  li.product-item,
  li.product-item:nth-child(n),
  li.ais-Hits-item {
    display: flex;
    visibility: visible;
    position: relative;
    width: 100%;
    min-width: 150px;
    max-width: none;
    margin: 0;
    overflow: hidden;
    transition: 300ms ease-in-out all;

    &.ais-Hits-item {
      @include mobile {
        border-bottom: 1px solid $grey;
      }
    }

    .product-item-info,
    .result-wrapper {
      display: flex;
      position: relative;
      z-index: 1 !important;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      transition: 300ms ease-in-out all;

      @include mobile {
        display: flex;
        padding-bottom: 20px;
      }

      .product-item-photo {
        &::before {
          content: "";
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          border-radius: 30px;
          background: #f5f5f5;
          aspect-ratio: 1/1;
          mix-blend-mode: darken;

          @include mobile {
            border-radius: 15px;
          }
        }

        .product-image-container {
          width: 100%;

          .product-image-wrapper {
            .product-image-photo {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 30px;

              @include mobile {
                border-radius: 15px;
              }

              .alttext-container {
                display: none;
              }
            }
          }
        }
      }

      .product-item-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @include mobile {
          margin-left: 20px;
        }

        .product-reviews-summary {
          display: flex;
          align-items: center;
          max-width: 170px;
          margin: 0 0 10px;

          .rating-summary {
            display: flex;
            left: 0;

            .rating-result {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: auto;

              &::before {
                display: none;
              }

              .stars {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &::before {
                  content: "\e605";
                  display: none;
                  background: $yellow;
                  background-clip: text;
                  letter-spacing: 0;
                  -webkit-text-fill-color: transparent;
                }

                &::after {
                  color: $black;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 14px;
                }

                &[data-stars="100"] {
                  &::after {
                    content: "5.0";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="99"],
                &[data-stars="98"],
                &[data-stars="97"] {
                  &::after {
                    content: "4.9";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="96"],
                &[data-stars="95"] {
                  &::after {
                    content: "4.8";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="94"],
                &[data-stars="93"] {
                  &::after {
                    content: "4.7";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="92"],
                &[data-stars="91"] {
                  &::after {
                    content: "4.6";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="90"] {
                  &::after {
                    content: "4.5";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="89"],
                &[data-stars="88"] {
                  &::after {
                    content: "4.4";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="87"],
                &[data-stars="86"] {
                  &::after {
                    content: "4.3";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="85"],
                &[data-stars="84"] {
                  &::after {
                    content: "4.2";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="83"],
                &[data-stars="82"] {
                  &::after {
                    content: "4.1";
                  }

                  &::before {
                    display: block;
                  }
                }

                &[data-stars="81"],
                &[data-stars="80"] {
                  &::after {
                    content: "4.0";
                  }

                  &::before {
                    display: block;
                  }
                }
              }

              .rating-stars {
                display: inline-block;
                color: $black;

                &::before {
                  display: none;
                }
              }
            }
          }

          .reviews-actions {
            display: inline-flex;
            align-items: center;
            height: 20px;
            margin: 0;
            margin-left: 5px;
            padding: 0 5px;
            background: $lightgrey;
            color: $grey;
            font-size: 12px;
            text-wrap: nowrap;

            a {
              margin: 0;
              color: $darkgrey;
              text-transform: lowercase;

              &:hover {
                color: $black;
                text-decoration: none;
              }
            }
          }
        }

        .product-item-inner {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        .product-item-name {
          display: -webkit-box;
          margin: 15px 0;
          overflow: hidden;
          color: $black;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: normal;
          hyphens: auto;
          overflow-wrap: anywhere;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;

          @include mobile {
            min-height: 1px;
          }

          a {
            &:hover {
              text-decoration: none;
            }
          }
        }

        .product-item-inner {
          .product-item-actions {
            .actions-secondary {
              display: none;
              position: absolute;
              z-index: 1;
              top: 1px;
              right: 1px;
              background: white;

              .towishlist {
                &::before {
                  content: "\e9da";
                  color: $red;
                  font-family: icomoon;
                  font-size: 14px;
                }
              }

              .tocompare {
                display: none;
              }
            }

            .actions-primary {
              display: flex;
              position: absolute;
              right: 0;
              bottom: 0;
              height: 40px;
              transition: 300ms ease-in-out transform;
              border-radius: none;

              @include mobile {
                display: none;
              }

              .unavailable {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0;
                transition: 300ms ease-in-out all;
                border: 1px solid $grey;
                border-radius: 0;
                border-radius: 20px;
                background: white;
                color: white;
                font-size: 14px;
                font-weight: 600;
                text-transform: none;

                span {
                  display: none;
                }

                &::after {
                  content: "\e902";
                  color: $black;
                  font-family: icomoon;
                }

                &:hover {
                  &::before {
                    content: "This product is currently unavailable.";
                    display: block;
                    position: absolute;
                    z-index: 999;
                    right: 0;
                    bottom: 30px;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    height: auto;
                    padding: 10px;
                    border: 1px solid #d4d4d4;
                    background: white;
                    color: #120f11;
                    font-size: 13px;
                  }
                }
              }

              form {
                flex: 1;
                width: 40px;

                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  margin: 0;
                  transition: 300ms ease-in-out all;
                  border: 0;
                  border-radius: 0;
                  border-radius: 20px;
                  background: $lightgrey;
                  color: white;
                  font-size: 14px;
                  font-weight: 600;
                  text-transform: none;

                  span {
                    display: none;
                  }

                  &::after {
                    content: "\e901";
                    color: $black;
                    font-family: icomoon;
                  }

                  &:hover {
                    background: $black;

                    &::after {
                      color: white;
                    }
                  }
                }
              }

              .product-item-details-link {
                display: none;
              }
            }
          }
        }

        .price-box {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 0;

          > span {
            display: flex;
            align-items: center;
            margin-right: 5px;

            .price {
              position: relative;
              padding-left: 0;
              color: $black;
              font-size: 21px;
              font-weight: 600;

              @include mobile {
                padding-left: 0;
              }
            }

            &.special-price {
              margin-right: 5px;

              .price-wrapper {
                .price {
                  position: relative;
                  padding-left: 0;
                  color: $black;
                  font-size: 21px;
                  font-weight: 600;

                  @include mobile {
                    padding-left: 0;
                  }
                }
              }
            }

            &.old-price {
              width: 100%;
              text-decoration: none;

              .price-wrapper {
                .price {
                  color: #adadad;
                  font-size: 14px;
                  font-weight: 600;

                  &::before {
                    display: none;
                  }
                }
              }

              &::before {
                content: "was";
                display: inline-block;
                margin-right: 3px;
                color: #adadad;
                font-weight: 600;
              }
            }

            &.msrp-wrapper {
              display: flex;
              align-items: center;

              &::after {
                content: "RRP means the recommended retail price, provided by the seller, manufacturer or supplier or is the recently advertised price for the same product on a different online platform. It is not necessarily the price a product previously offered for sale on Mytopia";
                display: none;
                position: absolute;
                z-index: 999;
                bottom: 30px;
                left: 50%;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: auto;
                padding: 10px;
                transform: translateX(-50%);
                border: 1px solid #d4d4d4;
                background: white;
                color: #120f11;
                font-size: 13px;

                @include mobile {
                  width: 320px;
                }
              }

              &:hover {
                &::after,
                &::before {
                  display: flex;
                }
              }

              .msrp-price {
                margin-top: 2px;
                margin-left: 10px;
                color: $red;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                text-decoration: line-through;

                @include mobile {
                  margin-left: 5px;
                }
              }

              .msrp-tag {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 12px;
                margin-left: 13px;
                padding-right: 2px;
                border-radius: 0 10px 10px 0;
                background: $red;
                color: white;
                font-size: 11px;
                font-weight: 400;
                line-height: 11px;
                text-decoration: none;

                @include mobile {
                  width: 24px;
                  height: 12px;
                  margin-left: 10px;
                  font-size: 9px;
                  line-height: 12px;
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -6px;
                  border-top: 6px solid transparent;
                  border-right: 6px solid $red;
                  border-bottom: 6px solid transparent;
                }
              }
            }

            .price-container {
              .price-label {
                display: none;
              }
            }
          }
        }

        .amshopby-option-link {
          display: inline-flex;
          align-self: flex-end;
          order: -1;
        }
      }

      .result {
        height: 100%;

        .result-content {
          display: flex;
          flex-direction: column;
          height: 100%;

          @include mobile {
            flex-direction: row;
          }

          .result-thumbnail {
            position: relative;
            padding-bottom: 0;
            border-radius: 30px;
            background: #f5f5f5;
            line-height: 1;
            aspect-ratio: 1/1;

            @include mobile {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 120px;
              min-width: 120px;
              padding-bottom: 0;
              background: transparent;

              &::before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                border-radius: 15px;
                background: #f5f5f5;
                aspect-ratio: 1/1;
                mix-blend-mode: darken;
              }
            }

            img {
              width: calc(100% - 20px);
              margin: 10px;
              object-fit: contain;
              font-size: 0;
              mix-blend-mode: darken;
            }

            .product-labels {
              position: absolute;
              top: 0;
              left: 0;
              width: calc(100%);
              padding-left: 0;
              transition: 500ms ease-in-out opacity;
              pointer-events: none;

              @include mobile {
                display: none;
              }

              li {
                display: flex;
                position: absolute;
                top: 15px;
                left: 15px;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 20px;
                margin: 0;
                padding: 0;
                padding: 0 15px;
                border-radius: 25px;
                background: $red;
                color: white;
                font-size: 11px;
                font-weight: 600;
                line-height: 11px;
                list-style: none;
                text-transform: uppercase;

                &.sale,
                &.Sale {
                  background-color: $red;
                }

                &.new,
                &.New {
                  background-color: $lightblue;
                  color: $brightblue;
                }

                &.hot,
                &.Hot {
                  background-color: $red;
                }

                &.clearance,
                &.Clearance,
                &.low.stock,
                &.Low.Stock,
                &.our.pick,
                &.Our.Pick {
                  display: flex;
                  z-index: 0;
                  top: 0;
                  right: 30px;
                  left: auto;
                  width: auto;
                  height: 20px;
                  padding: 0 15px;
                  border-radius: 0 0 10px 10px;
                }

                &.low.stock,
                &.Low.Stock,
                &.our.pick,
                &.Our.Pick, {
                  background-color: $brightblue;
                  color: white;
                }

                &.black.friday,
                &.Black.Friday,
                &.Cyber.Monday,
                &.cyber.monday,
                &.Express.Shipping,
                &.express.shipping {
                  display: flex;
                  z-index: 0;
                  top: 0;
                  right: 30px;
                  left: auto;
                  width: auto;
                  height: 20px;
                  padding: 0 15px;
                  border-radius: 0 0 10px 10px;
                  background: $black;
                }

                &.Express.Shipping,
                &.express.shipping {
                  padding: 0 5px;
                }

                &:not(.low.stock, .Low.Stock, .Express.Shipping, .express.shipping, .sale, .Sale, .new, .New, .hot, .Hot, .Clearance, .clearance, .black.friday, .Black.Friday, .cyber.monday, .Cyber.Monday) {
                  display: none;
                }
              }
            }

            .marquee-wrapper {
              position: relative;
              height: 20px;
              margin: 0 -20px;
              padding: 0;
              transform: rotate3d(1, 1, 1, -15deg) skew(-10deg);
              background: $red;
              color: white;
              counter-reset: menu;
              user-select: none;
              -webkit-touch-callout: none;

              --marquee-width: 200%;
              --offset: 25%;
              --move-initial: calc(-25% + var(--offset));
              --move-final: calc(-50% + var(--offset));

              @include mobile {
                position: absolute;
                top: auto;
                bottom: -30px;
                left: -15px;
                width: calc(100vw - 30px);
                margin: 0;
                transform: none;
              }
            }

            @keyframes marquee {
              0% {
                transform: translate3d(var(--move-initial), 0, 0);
              }

              100% {
                transform: translate3d(var(--move-final), 0, 0);
              }
            }

            .marquee-text {
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
              align-items: center;
              width: fit-content;
              height: 20px;
              transform: translate3d(var(--move-initial), 0, 0);
              transition: opacity 0.1s;
              transition-duration: 0.4s;
              animation: marquee 5s linear infinite;

              // animation-play-state: paused;
              animation-play-state: running;
              opacity: 1;
              text-wrap: nowrap;
              gap: 0;

              span {
                margin-right: 30px;
                font-weight: 600;
              }
            }

            .product-highlights {
              display: flex;
              position: absolute;
              z-index: 1;
              bottom: 30px;
              left: 0;
              flex-direction: column;
              justify-content: flex-end;
              height: 70px;
              padding-left: 0;
              transition: 500ms ease-in-out opacity;
              pointer-events: none;

              @include mobile {
                display: none;
              }

              li {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: flex-start;
                width: fit-content;
                max-width: 120px;
                height: 20px;
                margin: 0 0 5px;
                padding: 0 5px 0 10px;
                background: $black;
                color: white;
                font-size: 12px;
                font-weight: 600;
                list-style: none;

                &:last-child {
                  margin-bottom: 0;
                }

                span {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  overflow: hidden;
                  color: $lightgrey;
                  white-space: nowrap;
                }

                &::after {
                  content: "";
                  position: absolute;
                  right: -5px;
                  width: 10px;
                  height: 20px;
                  transform: skew(-15deg);
                  background: $black;
                }
              }
            }

            .product-brand {
              position: absolute;
              right: 0;
              bottom: 0;
              text-align: right;
              pointer-events: none;

              @include mobile {
                position: relative;
                transform: translateY(10px);
                text-align: left;
              }

              img {
                max-width: 75px;
                max-height: 30px;
                object-fit: contain;
              }
            }
          }

          > .result-sub-content {
            @include mobile {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 15px;
            }
          }

          .result-sub-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            @include mobile {
              gap: 5px;
            }

            .result-description {
              display: none;
            }

            .product-labels {
              display: flex;
              position: absolute;
              top: 0;
              right: 30px;
              padding: 0;
              pointer-events: none;

              &:not(:first-child) {
                display: inline-flex;
              }

              @include mobile {
                position: relative;
                top: 0;
                left: 0;
                gap: 5px;
              }

              li {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 20px;
                margin: 0;
                padding: 0 10px;
                border-radius: 0 0 10px 10px;
                background: $red;
                color: white;
                font-size: 11px;
                font-weight: 600;
                line-height: 11px;
                list-style: none;
                text-transform: uppercase;

                & ~ li {
                  display: none;

                  @include mobile {
                    display: inline-flex;
                  }
                }

                @include mobile {
                  height: 16px;
                  padding: 0 5px;
                  border-radius: 0;
                  font-size: 12px;
                  font-weight: 400;
                  text-transform: none;
                }

                &.new,
                &.New {
                  background-color: $lightblue;
                  color: $brightblue;
                }

                &.hot,
                &.Hot {
                  background-color: $red;
                }

                &.low.stock,
                &.Low.Stock,
                &.our.pick,
                &.Our.Pick, {
                  background-color: $brightblue;
                  color: white;
                }

                &.black.friday,
                &.Black.Friday,
                &.Cyber.Monday,
                &.cyber.monday,
                &.Express.Shipping,
                &.express.shipping {
                  background: $black;
                  color: white;
                }
              }
            }

            .product-highlights {
              display: none;

              @include mobile {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: auto;
                padding: 0;
                list-style: none;
                gap: 5px;

                li {
                  margin-bottom: 0;
                  padding: 0 3px;
                  background: $lightgrey;
                  color: $black;
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }

            .result-title {
              display: -webkit-box;
              margin: 15px 0;
              overflow: hidden;
              color: $black;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              text-align: left;
              text-overflow: ellipsis;
              white-space: normal;
              hyphens: auto;
              overflow-wrap: anywhere;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;

              @include mobile {
                min-height: 0;
                margin: 0;
                margin-bottom: auto;
              }
            }

            .ratings {
              .result-sub-content {
                .product-reviews-summary {
                  margin: 10px 0;
                }

                .rating-summary {
                  display: block;
                  left: 0;
                  margin-top: -15px;

                  @include mobile {
                    margin-top: 0;
                  }

                  .rating-result {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    @include mobile {
                      width: auto;
                    }

                    &::before {
                      display: none;
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;

                      &::before {
                        content: "\e605";
                        display: none;
                        background: $yellow;
                        background-clip: text;
                        letter-spacing: 0;
                        -webkit-text-fill-color: transparent;
                      }

                      &::after {
                        color: $black;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 14px;
                      }

                      &[data-stars="100"] {
                        &::after {
                          content: "5.0";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="99"],
                      &[data-stars="98"],
                      &[data-stars="97"] {
                        &::after {
                          content: "4.9";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="96"],
                      &[data-stars="95"] {
                        &::after {
                          content: "4.8";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="94"],
                      &[data-stars="93"] {
                        &::after {
                          content: "4.7";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="92"],
                      &[data-stars="91"] {
                        &::after {
                          content: "4.6";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="90"] {
                        &::after {
                          content: "4.5";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="89"],
                      &[data-stars="88"] {
                        &::after {
                          content: "4.4";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="87"],
                      &[data-stars="86"] {
                        &::after {
                          content: "4.3";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="85"],
                      &[data-stars="84"] {
                        &::after {
                          content: "4.2";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="83"],
                      &[data-stars="82"] {
                        &::after {
                          content: "4.1";
                        }

                        &::before {
                          display: block;
                        }
                      }

                      &[data-stars="81"],
                      &[data-stars="80"] {
                        &::after {
                          content: "4.0";
                        }

                        &::before {
                          display: block;
                        }
                      }
                    }

                    .rating-stars {
                      display: inline-block;
                      color: $black;

                      &::before {
                        display: none;
                      }
                    }
                  }
                }

                .price {
                  .price-wrapper {
                    text-align: left;

                    > div {
                      display: flex;
                      flex-wrap: wrap;
                    }

                    .after_special {
                      position: relative;
                      padding-left: 0;
                      color: $black;
                      font-size: 21px;
                      font-weight: 600;

                      @include mobile {
                        padding-left: 0;
                      }
                    }

                    .before_special {
                      display: inline-flex;
                      width: 100%;
                      color: #adadad;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 14px;
                      text-decoration: none;

                      &::before {
                        content: "was";
                        display: inline-block;
                        margin-right: 3px;
                        text-decoration: none;
                      }

                      @include mobile {
                        font-size: 14px;
                      }

                      span {
                        position: relative;

                        &::after {
                          content: "";
                          position: absolute;
                          right: 0;
                          width: 18px;
                          height: 100%;
                          transform: skewX(-15deg);
                          background: white;
                        }
                      }
                    }

                    .msrp-wrapper {
                      display: flex;
                      align-items: center;

                      & + .msrp-wrapper {
                        display: none;
                      }

                      &::after {
                        content: "RRP means the recommended retail price, provided by the seller, manufacturer or supplier or is the recently advertised price for the same product on a different online platform. It is not necessarily the price a product previously offered for sale on Mytopia";
                        display: none;
                        position: absolute;
                        z-index: 999;
                        bottom: 30px;
                        left: 50%;
                        align-items: center;
                        justify-content: center;
                        width: 200px;
                        height: auto;
                        padding: 10px;
                        transform: translateX(-50%);
                        border: 1px solid #d4d4d4;
                        background: white;
                        color: #120f11;
                        font-size: 13px;

                        @include mobile {
                          width: 320px;
                        }
                      }

                      &:hover {
                        &::after,
                        &::before {
                          display: flex;
                        }
                      }

                      .msrp-price {
                        max-width: 7.5ch;
                        margin-top: 2px;
                        margin-left: 10px;
                        overflow: hidden;
                        color: $red;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                      }

                      .msrp-tag {
                        display: flex;
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        width: 28px;
                        height: 12px;
                        margin-left: 13px;
                        padding-right: 2px;
                        border-radius: 0 10px 10px 0;
                        background: $red;
                        color: white;
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 11px;
                        text-decoration: none;

                        @include mobile {
                          width: 24px;
                          height: 12px;
                          margin-left: 10px;
                          font-size: 9px;
                          line-height: 12px;
                        }

                        &::before {
                          content: "";
                          position: absolute;
                          top: 0;
                          left: -6px;
                          border-top: 6px solid transparent;
                          border-right: 6px solid $red;
                          border-bottom: 6px solid transparent;
                        }
                      }
                    }

                    .minimal-price-link {
                      display: none;
                    }
                  }
                }
              }
            }

            .actions-primary {
              display: flex;
              position: absolute;
              right: 0;
              bottom: 0;
              height: 50px;
              transition: 300ms ease-in-out transform;
              border-radius: none;

              @include mobile {
                display: none;
              }

              .unavailable {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0;
                transition: 300ms ease-in-out all;
                border: 1px solid $grey;
                border-radius: 0;
                border-radius: 20px;
                background: white;
                color: white;
                font-size: 14px;
                font-weight: 600;
                text-transform: none;

                span {
                  display: none;
                }

                &::after {
                  content: "\e902";
                  color: $black;
                  font-family: icomoon;
                }

                &:hover {
                  &::before {
                    content: "This product is currently unavailable.";
                    display: block;
                    position: absolute;
                    z-index: 999;
                    right: 0;
                    bottom: 30px;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    height: auto;
                    padding: 10px;
                    border: 1px solid #d4d4d4;
                    background: white;
                    color: #120f11;
                    font-size: 13px;
                  }
                }
              }

              form {
                flex: 1;
                width: 40px;

                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  margin: 0;
                  transition: 300ms ease-in-out all;
                  border: 0;
                  border-radius: 0;
                  border-radius: 20px;
                  background: $lightgrey;
                  color: white;
                  font-size: 14px;
                  font-weight: 600;
                  text-transform: none;

                  span {
                    display: none;
                  }

                  &::after {
                    content: "\e901";
                    color: $black;
                    font-family: icomoon;
                  }

                  &:hover {
                    background: $black;

                    &::after {
                      color: white;
                    }
                  }
                }
              }

              .product-item-details-link {
                display: none;
              }
            }
          }
        }
      }
    }

    &:hover {
      .product-item-info,
      .result-wrapper {
        .result {
          .result-content {
            .result-thumbnail {
              .product-highlights,
              .product-brand,
              .product-labels {
                opacity: 0;

                @include mobile {
                  opacity: 1;
                }
              }
            }

            .marquee-wrapper {
              .marquee-text {
                animation-play-state: paused;
              }
            }
          }
        }
      }
    }
  }
}

.recommendations {
  max-width: 1920px;
  margin: 0 auto;

  ol.products {
    .slick-list {
      width: 100%;

      .slick-track {
        margin: 0;
      }
    }

    .product-item {
      .product-item-info {
        .product-item-photo {
          img {
            aspect-ratio: 1/1;
            object-fit: contain;
            border-radius: 30px;

            @include mobile {
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
}
