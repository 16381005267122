body {
  &.checkout-index-index {
    .page-wrapper {
      .page-bottom {
        .klaviyo {
          display: none;
        }
      }
    }
  }

  &:not(.checkout-index-index) {
    .page-wrapper {
      .page-bottom {
        padding: 0;

        .klaviyo {
          width: 100%;
          max-width: none;
          margin: 0;
          padding: 40px 15px;
          background: #fff;

          .klaviyo-form {
            form {
              max-width: 1280px;
              margin: 0 auto;

              > div {
                display: flex;
                flex-flow: row wrap !important;
                justify-content: space-between !important;

                @include mobile {
                  flex-direction: column !important;
                }

                div[data-testid="form-row"] {
                  box-sizing: border-box;
                  flex: 1;
                  padding: 0;

                  &:last-child > div {
                    align-self: flex-start;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

footer {
  &.page-footer {
    position: relative;
    padding: 0;

    #footer-container {
      position: relative;
      box-sizing: border-box;
      padding: 50px 0;
      background-color: $black;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: $redgradient;
      }

      .inner-container {
        display: flex;
        justify-content: space-between;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 15px;

        @include mobile {
          flex-direction: column;
        }

        .col {
          min-width: 150px;
          padding-right: 20px;
          color: white;

          img {
            max-width: 240px;
            margin-bottom: 10px;
          }

          h3 {
            font-family: Oswald;
            font-size: 21px;
            font-weight: 400;
            text-transform: uppercase;
          }

          a {
            color: white;
          }

          ul {
            padding: 0;

            li {
              margin-bottom: 5px;
              list-style: none;

              a {
                color: white;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .copyright {
      display: flex;
      min-height: 80px;
      background: $lightgrey;

      .inner-container {
        display: flex;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 20px;

        @include mobile {
          flex-direction: column;
        }

        .text {
          display: flex;
          flex: 1;
          align-items: center;
          min-height: 50px;

          p {
            margin: 0;
          }
        }

        .payment-methods {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-end;
          min-height: 50px;

          @include mobile {
            justify-content: flex-start;
          }

          ul {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            padding: 0;

            li {
              display: flex;
              justify-content: center;
              width: 50px;
              height: 30px;
              margin: 0 10px;
              padding: 5px;
              border-radius: 3px;
              background: white;
              list-style: none;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
