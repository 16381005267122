body {
  &.customer-account-forgotpassword {
    overflow-x: hidden;

    .page-main {
      .page-title-wrapper {
        position: relative;
        padding-bottom: 50px;
        background: $black;
        text-align: center;

        h1 {

        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -2000px;
          width: 2000px;
          height: 100%;
          background: $black;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -2000px;
          width: 2000px;
          height: 100%;
          background: $black;
        }

        .page-title {
          padding-top: 20px;
          color: white;
        }
      }

      .form {
        position: relative;
        z-index: 1;
        min-width: 0;
        max-width: 500px;
        margin: -50px auto 0;
        padding: 15px;
        background: white;

        fieldset {
          .legend {
            margin-left: 0;

            span {
              font-weight: 600;
            }
          }

          .field {
            &::before {
              display: none;
            }
          }
        }

        .actions-toolbar {
          margin-left: 0;

          .primary {
            width: 100%;

            button {
              width: 100%;
              height: 50px;
            }
          }
        }
      }
    }
  }
}
