.slick-slider {
  .slick-arrow {
    &.slick-next,
    &.slick-prev {
      display: flex;
      margin-top: -10px;
      background: none !important;

      &::before,
      &::after {
        color: $black;
      }

      &::before {
        content: "\e906";
        color: $black;
        font-family: icomoon;
        font-size: 11px;
      }
    }

    &.slick-prev {
      transform: rotate(90deg);
    }

    &.slick-next {
      transform: rotate(270deg);
    }
  }
}
