.block-brands {
  position: relative;
  max-width: 1310px;
  margin: 50px auto;
  padding: 30px 0 28px;
  color: #fff;

  .slick-slide {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }

  .pagebuilder-column-group {
    align-items: center;

    .col {
      text-align: center;

      @include mobile {
        flex-basis: auto;
        width: 50% !important;
      }
    }

    img {
      max-width: 120px !important;
      height: auto;
      transition: all 0.5s ease;
      opacity: 0.3;
      cursor: pointer;
      mix-blend-mode: darken;
      filter: saturate(0);

      &:hover {
        opacity: 1;
        filter: saturate(1);
      }
    }
  }
}
