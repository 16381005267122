.slick-slider-featured {
  .item {
    .inner-container {
      display: flex;
      align-items: center;
      max-width: 1920px;
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;

      @include mobile {
        align-items: flex-start;
        padding-top: 50px;
      }

      .text {
        color: white;
      }
    }
  }
}
