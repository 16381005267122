body {
  &.contact-index-index {
    overflow-x: hidden;

    .page-main {
      max-width: 1920px;

      .page-title-wrapper {
        position: relative;
        margin-bottom: -50px;
        padding-top: 20px;
        padding-bottom: 50px;
        background: $black;

        h1 {
          margin-bottom: 20px;
          color: white;
          font-family: "Oswald";
          font-weight: 400;
          text-transform: uppercase;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -2000px;
          width: 2000px;
          height: 100%;
          background: $black;
        }

        &::after {
          right: -2000px;
          left: auto;
        }
      }

      .columns {
        position: relative;
        z-index: 1;

        .column {
          display: flex;
          flex-direction: row-reverse;
          padding-top: 20px;
          gap: 20px;

          @include media(mobile, tabletportrait, tabletlandscape) {
            flex-wrap: wrap;
          }

          >div {
            flex: 1;
            width: 50%;

            &.widget {
              flex: 2;
              order: -1;
              padding: 15px;
              background: white;

              p {
                max-width: 720px;
              }

              h2 {
                font-size: 28px;

                &:first-child {
                  margin-top: 0;
                }
              }
            }

            @include media(mobile, tabletportrait, tabletlandscape) {
              flex: auto;
              width: 100%;
            }
          }

          form {
            padding: 15px;
            background: white;

            @include mobile {
              margin: 0 auto;
            }

            &.form.contact {
              width: 100%;
              min-width: 0;
              max-width: 520px;
            }

            fieldset {
              margin-bottom: 20px;

              legend {
                margin: 0;
                font-size: 28px;
                font-weight: 600;
              }

              .field {
                &.no-label {
                  &::before {
                    display: none;
                  }
                }
              }
            }

            .actions-toolbar {
              margin-left: 0;

              .primary {
                width: 100%;
                max-width: 520px;
                border: none;
                background: $red;
              }
            }
          }
        }
      }
    }
  }
}
