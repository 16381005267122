body {
  &.cms-no-route {
    overflow-x: hidden;

    .columns {
      .column {
        width: 100%;

        .error {
          h1,
          h2,
          h3,
          h4,
          p {
            text-align: center;
          }

          h1 {
            margin-bottom: 0;
            font-family: "Oswald";
            font-size: 150px;
            line-height: 150px;
            text-transform: uppercase;
          }

          h2 {

          }
        }
      }
    }
  }
}
