@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tabletportrait {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin tabletlandscape {
  @media (min-width: 1025px) and (max-width: 1280px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1281px) and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1441px) and (max-width: 1600px) {
    @content;
  }
}

@mixin fullhd {
  @media (min-width: 1601px) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if $key == mobile {
      @include mobile {
        @content;
      }
    } @else if $key == tabletportrait {
      @include tabletportrait {
        @content;
      }
    } @else if $key == tabletlandscape {
      @include tabletlandscape {
        @content;
      }
    } @else if $key == laptop {
      @include laptop {
        @content;
      }
    } @else if $key == desktop {
      @include desktop {
        @content;
      }
    } @else if $key == fullhd {
      @include fullhd {
        @content;
      }
    }
  }
}
