.amsl-popup-overlay {
  .amsl-popup-block {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    max-width: 570px;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    transform: translate(-50%, -50%) !important;
    border-radius: 0;

    .amsl-tabs-wrapper {
      .amsl-tablist {
        .amsl-title {
          &.active {
            background: white;

            a {
              border-bottom: 1px solid $brightblue;
              color: $brightblue;
            }
          }
        }
      }
    }

    .actions-toolbar {
      .primary {
        width: 100%;
        font-weight: 600;

        .action {
          &.primary {
            border: none;
            background: $redgradient;
          }
        }
      }
    }

    .checkbox {
      width: 30px;
      height: 30px;
    }

    .amsl-form {
      label {
        color: $black;
      }
    }
  }
}

.modal-popup {
  &._show {
    z-index: 1002 !important;
  }

  .modal-inner-wrap {
    top: 50% !important;
    left: 50% !important;
    width: calc(100% - 20px) !important;
    max-width: 500px;
    margin: 0 !important;
    transform: translate(-50%, -50%) !important;

    .modal-footer {
      display: flex;
      justify-content: space-between;

      button {
        min-width: 120px;

        &.action-secondary {
          background: $black;
        }
      }
    }
  }
}

.modals-overlay {
  z-index: 1001 !important;
}
