@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700;800&family=Oswald:wght@500&display=swap");

@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/icomoon/icomoon.eot?1z3gcd");
  src:
    url("../fonts/icomoon/icomoon.eot?1z3gcd#iefix") format("embedded-opentype"),
    url("../fonts/icomoon/icomoon.ttf?1z3gcd") format("truetype"),
    url("../fonts/icomoon/icomoon.woff?1z3gcd") format("woff"),
    url("../fonts/icomoon/icomoon.svg?1z3gcd#icomoon") format("svg");
  font-display: block;
}

html,
body,
input,
label,
::placeholder,
input[type="text"]::input-placeholder,
select {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
}

p {
  font-weight: 400;
}

h1 {
  font-weight: 800;
}

strong {
  font-weight: 600;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
}
