body {
  &.customer-account-login {
    overflow-x: hidden;

    .page-main {
      .page-title-wrapper {
        position: relative;
        padding-bottom: 50px;
        background: $black;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -2000px;
          width: 2000px;
          height: 100%;
          background: $black;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -2000px;
          width: 2000px;
          height: 100%;
          background: $black;
        }

        .page-title {
          width: 100%;
          padding-top: 20px;
          color: white;
          text-align: center;
        }
      }

      .columns {
        .column {
          .amsl-login-page {
            display: flex;
            position: relative;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin: -50px auto 50px;
            gap: 20px;

            >div,
            >span {
              display: block;
              width: 100%;
              max-width: 500px;
              padding: 15px;
              background: white;

              @include mobile {
                margin: 0 auto;
              }
            }

            .amsl-separator {
              border: none;

              @include mobile {
                display: none;
              }

              .amsl-icon {
                display: none;
              }
            }

            .amsl-social-wrapper {
              background: white;

              .amsl-social-login {
                padding: 0;
                background: none;

                .amsl-buttons {
                  display: flex;
                  gap: 10px;
                  width: 100%;

                  .amsl-button-wrapper {
                    flex: 1;
                    margin: 0;
                  }
                }
              }
            }
          }

          .login-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            &::before,
            &::after {
              display: none;
            }

            @include mobile {
              flex-direction: column-reverse;
              gap: 0;
            }

            .block {
              position: relative;
              z-index: 1;
              min-width: 0;
              max-width: 500px;
              margin-top: -50px;
              margin-bottom: 0;
              padding: 15px;
              background: white;

              &.block-new-customer {
                margin-top: -130px;

                @include mobile {
                  margin-top: -50px;
                }
              }

              @include mobile {
                width: 100%;
                margin-right: auto;
                margin-left: auto;
              }

              .block-title {
                strong {
                  font-weight: 600;
                }
              }

              .block-content {
                form {
                  fieldset {
                    &::after {
                      margin-left: 0;
                    }

                    .field {
                      &.choice {
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                }

                .actions-toolbar {
                  width: 100%;
                  margin-top: 20px;
                  margin-left: 0;

                  .primary {
                    width: 100%;

                    button,
                    .action {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 40px;
                    }
                  }
                }
              }
            }
          }

          .amsl-login-page {

          }
        }
      }
    }
  }
}
