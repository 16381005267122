.page-footer {
  .mailing-list-container {
    padding: 40px 0;
    background-color: white;

    @include mobile {
      background-size: auto 100%;
    }

    .inner-container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 15px;
      gap: 20px;

      @include mobile {
        flex-direction: column;
      }

      .text {
        flex: 1;
        width: 100%;

        h4 {
          margin: 0;
          padding: 0;
          color: $black;
          font-size: 21px;
          font-weight: 800;
        }

        p {
          margin: 0;
          padding: 0;
          color: $black;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .block.newsletter {
        flex: 1;
        width: 100%;
        margin-bottom: 0;

        .form {
          display: flex;

          .newsletter {
            width: 100%;
            max-width: none;
            margin-top: 0;

            .control {
              &::before {
                content: "\ea84";
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                color: #121011;
                font-family: icomoon;
                font-size: 15px;
              }

              input {
                background: $lightgrey;
                font-family: Fira Sans;

                &:not([disabled]) {
                  &:not([type="checkbox"]) {
                    &:focus {
                      border: 1px solid $brightblue;
                      box-shadow: none;
                    }

                    &.mage-error {
                      border: 1px solid $red;
                      color: $black;

                      &:focus {
                        border: 1px solid $red;
                        box-shadow: none;
                      }
                    }
                  }
                }

                &::input-placeholder {
                  font-family: Fira Sans;
                }
              }

              #placeholder {

              }
            }
          }

          .actions {
            display: block;
            width: 120px;

            .action {
              width: 120px;
              height: 40px;
              border-color: transparent;
              background: $redgradient;
              color: white;
            }
          }
        }
      }
    }
  }
}

body.logged-in .mailing-list-container {
  display: none;
}
